/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/jquery.terminal@2.33.3/js/jquery.terminal.min.js
 * - /npm/jquery.terminal@2.33.3/js/xml_formatting.js
 * - /npm/js-polyfills@0.1.43/keyboard.js
 * - /npm/prismjs@1.29.0/prism.min.js
 * - /npm/jquery.terminal@2.33.3/js/prism.js
 * - /npm/jquery.splitter@0.28.5/js/jquery.splitter.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
